import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import axios from 'axios'
import 'ant-design-vue/dist/antd.css'
import 'moment/locale/zh-cn';
import VueParticles from 'vue-particles'

axios.defaults.timeout = 15000;
axios.defaults.baseURL = 'https://api.yiyouzz.top';

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.use(Antd)
Vue.use(VueParticles)

//http request 封装请求头拦截器
axios.interceptors.request.use(
  config => {
    let token = sessionStorage.getItem('token');
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  }
);
// 设置axios的返回拦截
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.data == undefined) { // 判断请求异常信息中是否超时  
      Vue.prototype.$notification["error"]({
        message: "系统提示",
        description: "网络超时,请检查服务器!",
        duration: 2.5,
        key: 2
      });
    } else {
      Vue.prototype.$notification["error"]({
        message: "系统提示",
        description: error.response.data.msg,
        duration: 2.5,
        key: 2
      });
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
