import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    hasMoneyOrder: false,
  },
  mutations: {
    setHasMoneyOrder(state, hasOrder){
			state.hasMoneyOrder = hasOrder
    }
  }
})

export default store